import React from 'react'

const Empty = () => {
  return (
    <div>
      <div className="fixed top-0 -z-20 right-0 w-screen h-screen back">

      </div>
    </div>
  )
}

export default Empty
